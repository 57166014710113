import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {Router} from '@angular/router';
import * as moment from 'moment/moment';
import {differenceInSeconds} from 'date-fns';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router) {
  }

  canActivate() {

    if (localStorage.getItem('userLogged')) {
      const payload = JSON.parse(atob(localStorage.getItem('userLogged').split('.')[1]));

      console.log('DEBUG', moment(payload.exp).diff(moment(), 'second'), differenceInSeconds(new Date(), new Date(payload.exp)));

      if (moment(payload.exp).diff(moment(), 'second') >= 0) {
        return true;
      } else {
        console.log('expired');
        this.router.navigate(['/login']);
        return false;
      }
    }

    console.log('no userlogged');
    this.router.navigate(['/login']);
    return false;
  }
}
