import {BrowserModule} from '@angular/platform-browser';
// import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule, LOCALE_ID} from '@angular/core';

// import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AuthGuard} from './shared/auth.guard';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app.routing';
import {LoaderComponent} from './http-utility/loader/loader.component';
import {LoaderService} from './http-utility/loader/loader.service';
import {registerLocaleData} from '@angular/common';
import localeIt from '@angular/common/locales/it';
import {HttpService} from './http-utility/http.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

registerLocaleData(localeIt, 'it-IT');

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule,
    // NgbModule.forRoot(),
    AppRoutingModule,
    NgbModule,
    // BrowserAnimationsModule
  ],
  exports: [
    LoaderComponent
  ],
  providers: [
    AuthGuard,
    HttpService,
    LoaderService,
    {
      provide: LOCALE_ID,
      useValue: 'it-IT' // 'de-DE' for Germany, 'fr-FR' for France ...
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
