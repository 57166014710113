import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

@Injectable()
export class HttpService {
  apiUrl: string = environment.apiUrl;

  constructor(protected http: HttpClient) {}

  ricerca<T>(url, parametri): Observable<T> {
    // url = 'orionweb/' + url;

    if (url) {
      let params = new HttpParams();
      for (const prop in parametri) {
        if (
          parametri[prop] &&
          typeof parametri[prop] === "object" &&
          parametri[prop].date
        ) {
          const dataObj = parametri[prop].date,
            data = dataObj.year + "-" + dataObj.month + "-" + dataObj.day;

          params = params.append(prop, data);
        } else {
          params = params.append(prop, parametri[prop]);
        }
      }

      return this.http
        .get<T>(this.apiUrl + url, { observe: "response", params: params })
        .pipe(
          tap((result) => {
            this.settaNuovoToken(result);
            return result;
          }),
          map((result: HttpResponse<T>) => {
            return result.body;
          }),
        );
    }

    return null;
  }

  ricercaParams<T>(url, params): Observable<T> {
    // url = 'orionweb/' + url;

    if (!url) {
      return null;
    }
      return this.http
        .get<T>(this.apiUrl + url, {
          observe: "response",
          params: { ...params },
        })
        .pipe(
          tap((result) => {
            this.settaNuovoToken(result);
            return result;
          }),
          map((result: HttpResponse<T>) => {
            return result.body;
          }),
        );
        
      // if (params) {
      //   return this.http.get<any>(this.apiUrl + url, {observe: 'response', params: params}).subscribe(result => {
      //     // console.log('x-token', result.headers.get('x-token'));
      //     this.settaNuovoToken(result);

      //     cb(result.body);
      //   });
      // } else {
      //   return this.http.get<any>(this.apiUrl + url, {observe: 'response'}).subscribe(result => {
      //     // console.log('x-token', result.headers.get('x-token'));
      //     this.settaNuovoToken(result);

      //     cb(result.body);
      //   });
      // }    
  }

  download(url, filename) {
    // url = 'orionweb/' + url;

    let headers = new HttpHeaders().set("Content-Type", "application/pdf");
    headers = headers.append("Accept", "application/pdf");
    const params = new HttpParams().set("filename", filename);

    return this.http.get(this.apiUrl + url, {
      headers: headers,
      responseType: "blob",
      params: params,
    });
  }

  stampa(url) {
    // url = 'orionweb/' + url;
    let headers = new HttpHeaders().set("Content-Type", "application/pdf");
    headers = headers.append("Accept", "application/pdf");

    return this.http.get(this.apiUrl + url, {
      headers: headers,
      responseType: "blob",
    });
  }

  getImage(url: string, file: string): Observable<Blob> {
    // url = 'orionweb/' + url;
    let headers = new HttpHeaders().set("Content-Type", "application/image");
    headers = headers.append("Accept", "application/image");

    return this.http.get(this.apiUrl + url, {
      headers: headers,
      responseType: "blob",
      params: { file: file },
    });
  }

  async getImageSync(url: string, file: string) {
    let app: any = null;

    let headers = new HttpHeaders().set("Content-Type", "application/image");
    headers = headers.append("Accept", "application/image");
    try {
      return await this.http
        .get(this.apiUrl + url, {
          headers: headers,
          responseType: "blob",
          params: { file: file },
        })
        .toPromise();
    } catch (err) {
      console.log("Errore in ricercaSync: ", err);
    }
    // return app;
  }

  chiamataLibera(url) {
    return this.http.put<any>(this.apiUrl + url, {});
  }

  salva(url, record) {
    if (record._id) {
      return this.http.put<any>(this.apiUrl + url + record._id, record);
    } else {
      return this.http.post<any>(this.apiUrl + url, record);
    }
  }

  salvaTempo(url, record) {
    return this.http.put<any>(this.apiUrl + url + record._id, record);
  }

  elimina(url) {
    return this.http.delete<any>(this.apiUrl + url);
  }

  settaNuovoToken(result) {
    if (result.headers.get("x-token")) {
      const token = result.headers.get("x-token"),
        payload = JSON.parse(atob(token.split(".")[1]));

      localStorage.setItem("isLoggedin", "true");
      localStorage.setItem("userLogged", token);
      localStorage.setItem("userName", payload.user.name);
      localStorage.setItem("userPayload", JSON.stringify(payload.user));
      localStorage.setItem("iat", payload.iat);
      localStorage.setItem("exp", payload.exp);
    }
  }

  checkFormValue(val, def?) {
    let ret = def !== null && def !== undefined ? def : "";

    if (val) {
      ret = val;
    }

    return ret;
  }

  sort(array: any, field: string): any[] {
    if (!Array.isArray(array)) {
      return;
    }
    array.sort((a: any, b: any) => {
      if (a[field] < b[field]) {
        return -1;
      } else if (a[field] > b[field]) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }
}
